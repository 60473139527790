import { React, useState } from 'react';
import { Button, Divider, Space } from 'antd-mobile';
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'

const GetAlbumPlus = () => {
    const [result, setResult] = useState({})
    const [apiParams, setApiParams] = useState({})


    const handleNormalCallback = (resp) => {
        setResult(resp)
    }

    const handleBankCardDetect = () => {
        let data = {
            type:0,
            sourceType:0,
            limitCount:3,
            compressMaxSize:1500,
            currentSupplier: 'TC',
            tcSdkParamsUrl: '/hbapplycollector/supplier/liveness/tc/sdk/params',

        }
        setApiParams(data)
        fcs.openModule('getAlbumPlus', data, (resp) => {
            handleNormalCallback(resp)
        })
    }

    return (
        <div>
            <Divider>GetAlbumPlus</Divider>
            <Button color='primary' onClick={handleBankCardDetect}>获取相册图片</Button>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default GetAlbumPlus