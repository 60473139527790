import { React, useEffect, useState } from 'react'
import { Button, Divider, Space, Toast } from 'antd-mobile'
import ApiResult from './ApiResult'
import { fcs } from '../utils/functions'
import { Col, Row } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import VConsole from 'vconsole'

const RecommendSwitch = () => {
    const [result, setResult] = useState({})
    const [apiParams, setApiParams] = useState({})

    const location = useLocation();
    const query = new URLSearchParams(useLocation().search);

    useEffect(() => {
        const vConsole = new VConsole();
        console.log('hadi =', `test page componentDidMount ${window.location.href}`);    

        return () => {
            console.log('hadi =', 'test page componentWillUnmount');
            vConsole.destroy();
        }
    }, [location, query]);

    useEffect(() => {
        window.dmlife = {
            ...window.dmlife,
            pageDidBecomeVisible: (params) => {
                const str = JSON.stringify(params);
                console.log('hadi -', str);
                Toast.show(`收到 pageDidBecomeVisible 消息: ${str}`);
                return {a: '3333'};
            },
        };
    
        return () => {
        };
      }, []);

    const handleNormalCallback = (resp) => {
        setResult(resp)
    }

    const openModuleHandler = (name) => {
        let data = {}
        
        if (name === 'RECOMMEND_SWITCH') {
            data.recommendSwitch = 'on';
        } else if (name === 'BIOMETRIC_SWITCH') {
            data.biometricSwitch = true;
        } else if (name === 'getContactAndCallLogStatus') {

        }
        
        setApiParams(data)
        fcs.openModule(name, data, (resp) => {
            handleNormalCallback(resp)
        })
    }

    const invokeJSHandler = (name) => {
        let params = [];
        // let data = {}

        const callback = (resp) => {
            console.log('hadi =', 'receive response', resp);
            handleNormalCallback(resp);
        };
        
        if (name === 'track1') {
            params = [
                {
                    isNewTrack: true,
                    priority: 1,
                    trackObj: {}
                },
                callback
            ];
            name = 'track';
        } else if (name === 'track2') {
            params = [
                {
                    isNewTrack: true,
                    priority: 1,
                    trackObj: [{}]
                },
                callback
            ];
            name = 'track';
        } else if (name === 'pasteToClipboard') {
            params = [
                '我是测试字符串',
                callback
            ];
        } else if (name === 'openURLInNative' || name === 'openWebModule') {
            params = [
                'https://m.baidu.com',
                null,
                callback,
                null,
            ];
        } else if (name === 'getModuleParamForKey') {
            const key = 'sessionId';
            const res = window.dmlife.getModuleParamForKey(key);
            setApiParams({key});
            handleNormalCallback(res);
            return;
        } else if (name === 'dismissCurrentModule' || name === 'dismissCurrentModule2') {
            params = [
                {
                    animate: true,
                    completeFlow: name === 'dismissCurrentModule2',
                },
                callback
            ];
            name = 'dismissCurrentModule';
        } else if (name === 'reloadCurrentModule') {
            params = [
                {
                    testa: 'testa'
                }
            ]
        }
        else {
            params = [callback]
        }
        
        setApiParams(params);
        // fcs.invoke(name, data)
        fcs.invokeDynamicArgs(name, params);
    }

    const test = () => {
        // Toast.show(window.greet2);
        // window.greet();
        // window.greet2();
        window.dmlife.pageDidBecomeVisible();
    };

    return (
        <div>
            <Button color='primary' onClick={() => { window.history.back(); }}>goback</Button>
            <Button color='primary' onClick={() => { test() }}>test</Button>        
            <Divider>PasteBoardAndJump</Divider>
            <Row gutter={[12, 12]} style={{marginLeft: '12px'}}>
                <Col>
                    <Link onClick={() => openModuleHandler('RECOMMEND_SWITCH')}>个性化推荐开关</Link>
                </Col>
                <Col>
                    <Link color='primary' onClick={() => openModuleHandler('BIOMETRIC_SWITCH')}>生物识别开关</Link>
                </Col>
                <Col>
                    <Link color='primary' onClick={() => openModuleHandler('getContactAndCallLogStatus')}>通讯录权限状态</Link>
                </Col>
                <Col>
                    <Link color='primary' onClick={() => invokeJSHandler('track1')}>单条埋点</Link>
                </Col>
                <Col>
                    <Link color='primary' onClick={() => invokeJSHandler('track2')}>批量埋点</Link>
                </Col>
                <Col>
                    <Link color='primary' onClick={() => invokeJSHandler('refreshSession')}>refresh session</Link>
                </Col>
                <Col>
                    <Link color='primary' onClick={() => invokeJSHandler('pasteToClipboard')}>pasteToClipboard</Link>
                </Col>
                <Col>
                    <Link color='primary' onClick={() => invokeJSHandler('openURLInNative')}>openURLInNative</Link>
                </Col>
                <Col>
                    <Link color='primary' onClick={() => invokeJSHandler('openWebModule')}>openWebModule</Link>
                </Col>
                <Col>
                    <Link color='primary' onClick={() => invokeJSHandler('getModuleParamForKey')}>getModuleParamForKey</Link>
                </Col>
                <Col>
                    <Link color='primary' onClick={() => invokeJSHandler('dismissCurrentModule')}>dismissCurrentModule-pop-animated </Link>
                </Col>
                <Col>
                    <Link color='primary' onClick={() => invokeJSHandler('dismissCurrentModule2')}>dismissCurrentModule-endFlow-animated </Link>
                </Col>
                <Col>
                    <Link color='primary' onClick={() => invokeJSHandler('reloadCurrentModule')}>reloadCurrentModule </Link>
                </Col>
            </Row>
            <Space style={{ margin: "10px" }} direction='vertical' block >
                <ApiResult result={result} apiParams={apiParams} />
            </Space>
        </div>
    )
}

export default RecommendSwitch